import React from 'react';
import Error from 'next/error';
import { ExternalLayout } from '@app/components/layouts/External';

const NotFoundPage = () => {
  return <Error statusCode={404} />;
};

NotFoundPage.Layout = ExternalLayout;

export default NotFoundPage;
